<template>
    <div>
        <div class="flex items-center md:items-end mb-10">
            <slot name="title">
                <UIHeading class="mr-auto">
                    <slot name="title-text"/>
                </UIHeading>
            </slot>

            <slot name="heading-block"/>

            <ULink class="hidden md:block ml-auto font-bold text-blue-400 underline hover:no-underline hover:text-blue-500" v-if="readMoreLink" :to="readMoreLink">See All Services</ULink>
        </div>

        <div class="grid grid-cols-2 md:grid-cols-12 gap-2.5 md:gap-11">
            <div v-for="image of images" :class="image.className">
                <NuxtImg :src="image.src" :alt="image.title" loading="lazy" class="object-cover h-full w-full max-w-full rounded-0.5xl" format="webp"/>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps(['readMoreLink']);

const {data: images} = await useAsyncData('gallery', () => queryContent('data/gallery').findOne(), {
    transform: ({body}) => {
        return body.map((item, index) => {
            item.className = index % 3 > 0 ? 'md:col-span-5' : 'md:col-span-7'
            return item;
        });
    }
});
</script>
